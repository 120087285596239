import { ref, onUnmounted } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { getUserRole } from '@/auth/utils';
import userStoreModule from '../userStoreModule';

export default function useProfileView() {
  const STORE_MODULE_NAME = 'user';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, userStoreModule);
  }

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME);
    }
  });
  // Use toast
  const toastification = toast();

  const userRole = getUserRole();
  const blankItem = {};

  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  //   API Call
  const fetchProfile = () => {
    store
      .dispatch('user/getUserProfile')
      .then(response => {
        item.value = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  return {
    item,
    userRole,
    resetItem,
    fetchProfile,
  };
}
