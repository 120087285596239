<template>
  <div>

    <template v-if="item">
      <!-- First Row -->
      <b-row>
        <b-col cols="12">
          <profile-detail-card
            :user-data="item"
            @on-profile-updated="fetchProfile"
          />
        </b-col>
      </b-row>
      <b-row v-if="item && userRole && userRole.typeId === 'host'">
        <b-col cols="12">
          <subscription-card :user-data="item" />
        </b-col>
      </b-row>

    </template>

  </div>
</template>

<script>

import {
  BRow, BCol,
} from 'bootstrap-vue';
import ProfileDetailCard from './ProfileDetailCard.vue';
import SubscriptionCard from './SubscriptionCard.vue';
import useProfileView from "./useProfileView";

export default {
  components: {
    BRow,
    BCol,
    // Local Components
    ProfileDetailCard,
    SubscriptionCard,
  },
  created() {
    this.fetchProfile();
  },
  setup() {
    const {
      item,
      userRole,
      fetchProfile,
    } = useProfileView();

    return {
      item,
      userRole,
      fetchProfile,
    };
  },
};
</script>

<style></style>
