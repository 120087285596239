<template>
  <div v-if="role">

    <b-card no-body>
      <b-card-header>
        <b-card-title>
          {{ t('Thông tin gói cước') }}
        </b-card-title>
      </b-card-header>
      <hr class="mt-0">
      <b-card-body>

        <dl class="row">
          <dt class="col-sm-3">
            {{ t('Số phòng') }}:
          </dt>
          <dd class="col-sm-9">
            {{ role.maxRoomSlots ? Number(role.maxRoomSlots).toLocaleString() : 'Không giới hạn' }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-3">
            {{ t('Số giường') }}:
          </dt>
          <dd class="col-sm-9">
            {{ role.maxBedSlots ? Number(role.maxBedSlots).toLocaleString() : 'Không giới hạn' }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-3">
            {{ t('Số tin nhắn ZNS còn lại') }}:
          </dt>
          <dd class="col-sm-9">
            {{ role.zaloZnsCount }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-3">
            {{ t('Số giao dịch gạch nợ tự động còn lại') }}:
          </dt>
          <dd class="col-sm-9">
            {{ role.tingeeTransactionCount }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-3">
            {{ t('Số giao dịch gạch nợ tự động đã dùng') }}:
          </dt>
          <dd class="col-sm-9">
            {{ userData.usedTingeeTransactions }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-3">
            {{ t('Ngày hết hạn') }}:
          </dt>
          <dd class="col-sm-9">
            {{ parseDateToString(role.expiredIn) }}

          </dd>
        </dl>

      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardTitle, BCardHeader, BCardBody,
} from 'bootstrap-vue';
import { getUserRole, parseDateToString } from '@/auth/utils';
import { useUtils as useI18nUtils } from '@core/libs/i18n';

export default {
  components: {
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
  },
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const role = getUserRole();
    const { t } = useI18nUtils();

    return {
      role, parseDateToString, t,
    };
  },
};
</script>
